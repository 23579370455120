.MonitorScreenWrapper{
    width: calc(100% - 60px);
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    /*padding-bottom: 11em;*/
    position: relative;
}
.MonitorScreenWrapper:hover > .MonitorVideoFullscreen{
    visibility: visible;
    transition: visibility ease-in-out 0.3s;
}
.MobileScreenWrapper{
    width: 100% !important;
}
.MonitorVideo{
    /*transform: scaleX(1.9) !important;*/
}
.MonitorVideoFullscreen{
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 99999999;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.DisabledMenu{
    color: #6e6e6e !important;
    cursor: initial !important;
}

.MonitorVideoFullscreenButton{
    width: 92px;
    height: 92px;
    border: 7px solid #b9b9b9;
    color: #b9b9b9;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 34px;
    cursor: pointer;
    margin-right: 5px;
}
.MonitorVideoFullscreenButton:hover{
    border-color: #fff;
    color: #fff;
    transition: all ease-in-out 0.4ms;
}
.MonitorBackground{
    background-size: cover;
    height: 100%;
    width: 100%;
}

.VideoPlayer{

    height: 100% !important;
    overflow: visible !important;
    transform: scale(1) rotate(-90deg);

}
.VideoPlayer video{
    width: 800px !important;
}
.QualityWrapper{
    position: absolute;
    z-index: 9999999999;
    left: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
}
.QualityWrapper li{
    margin-right: 10px;
    background: #3e3e3e;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}
.QualityWrapper li:hover,
.QualityWrapper :global(li.active){
    color: #1dee19;
    transition: all ease-in-out 0.3s;
}
.QualityWrapper :global(li.active){
    font-weight: bold;
}
.MobileQualityWrapper{
    left: auto;
    bottom: 25px;
}